import React, { useEffect, useState, useRef } from "react";
import NavBar from "../../../components/NavBar";
import { QRCode, Form, Input, Button, Image, Divider, message, Row, Col } from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/loadersSlice";
import { getProfileById } from "../../../apicalls/profiles";
import html2canvas from 'html2canvas';


function PublicProfile() {
  const params = useParams();

  const dispatch = useDispatch();

  const [savecontact, setsavecontact] = useState();
  const [roll, setroll]= useState()

  const [expdate, setexpdate] = useState();

  const contentRef = useRef(null);

  const getData = async () => {
    try {
      dispatch(setLoading(true));
      //   console.log("params._id" + JSON.stringify(params));
      const response = await getProfileById(params.id);

      if (response.success) {
        dispatch(setLoading(false));
        //  console.log("params._id" + JSON.stringify(response));
        // console.log("params._id" + (response.data.owner.trialenddate));
        setProfileVal(response.data);
        setroll(response.data.owner.roll)

        // var todaysDate = new Date();
        // console.log("Today : ", todaysDate.toString() + "  exp ", response.data.owner.trialenddate);

        setexpdate(response.isExpired);
      } else {
        dispatch(setLoading(false));
        throw new Error(response.message);
      }
    } catch (error) {
      message.error(error.message);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [profileval, setProfileVal] = useState({
    firstName: "xxxx",
    lastName: "xxxx",
    companyName: "xxxx",
    designation: "xxxx",
    website: "xxxx.com",
    email: "xxxx",
    trialenddate: "",
    facebook: "https://www.facebook.com/",
    tweeter: "xxxx",
    instagram: "https://www.instagram.com/",
    linkedin: "https://www.linkedin.com/",
    mobileno: "xxxx",
    imageurl:
      "https://res.cloudinary.com/sanketg/image/upload/v1685304129/TallyLogo23_vkyacc.jpg",
  });

  const qrloc = useLocation();


  const shareToWhatsApp = async () => {
  

    try {
      // Capture the element as a canvas
      const canvas = await html2canvas(contentRef.current);

      // Convert the canvas to a data URL (base64 string)
      const image = canvas.toDataURL('image/png');

      // WhatsApp sharing URL (for mobile devices, attach image as a public link for better compatibility)
      const whatsappMessage = `Check out my Digital ID Card!`;
      const encodedMessage = encodeURIComponent(whatsappMessage);
      const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;

      // Log the generated image (for debugging or uploading to cloud storage)
      console.log('Generated Image Base64:', image);

      // Simulate a download (optional, for testing locally)
      const link = document.createElement('a');
      link.href = image;
      link.download = 'digital-id-card.png';
      link.click();

      // Redirect to WhatsApp with the message (and later include a link to the uploaded image)
      window.open(whatsappUrl, '_blank');
    } catch (error) {
      console.error('Error capturing the image:', error);
    }
  };


  const shareToWhatsAppClaud = async () => {
    dispatch(setLoading(true));

    try {
      const canvas = await html2canvas(contentRef.current,{
        useCORS:true
      });


      const blob = await new Promise(resolve => {
        canvas.toBlob(resolve, 'image/png');
      });
      
      const file = new File([blob], `${profileval?.firstName}.png`, { type: 'image/png' });

      if (navigator.share && navigator.canShare({ files: [file] })) {

        dispatch(setLoading(false));
        
        await navigator.share({
          files: [file],
          title: `${profileval?.firstName} Profile`,
          text: 'Check out my Profile from knowMe!'
        });


      } else {
        const url = URL.createObjectURL(blob);

        setsavecontact(url);
        dispatch(setLoading(false));


        const a = document.createElement('a');
        a.href = url;
        a.download = `${profileval?.firstName}.png`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
     
      }
    } catch (error) {
      console.error('Error sharing:', error);
      alert('Error sharing image. Please try again.');
      dispatch(setLoading(false));

    }
  };


  const  SavetoContact = () => {
    // var contact = {
    //   name: "John Smith",
    //   phone: "555-555-5555",
    //   email: "john@example.com",
    // };

    dispatch(setLoading(true));
    const urlofcard = process.env.REACT_APP_DOMAIN + qrloc.pathname;



    var vcard1 =
      "BEGIN:VCARD\nVERSION:3.0\nFN:" +
      profileval?.firstName +
      " " +
      profileval?.lastName +
      "\nN:" +
      profileval?.firstName +
      " " +
      profileval?.lastName +
      "\nTEL;TYPE=work,voice:" +
      profileval?.mobileno +
      "\nURL:" +
      urlofcard +
      "\nEMAIL;TYPE=work:" +
      profileval?.email +
      "\nTITLE:" +
      profileval?.designation +
      "PROFILE:VCARD" +
      "\nORG:" +
      profileval?.companyName +
      "\nEND:VCARD";

    var todaysDate = new Date();
    //        console.log("Today : ", todaysDate.toString() + "  exp ", response.data.owner.trialenddate);

    var vcard =
      "BEGIN:VCARD" +
      "\nVERSION:3.0" +
      "\nREV:" +
      todaysDate.toString() +
      "\nN;CHARSET=utf-8:" +
      profileval?.firstName +
      " " +
      profileval?.lastName +
      ";;;" +
      "\nFN;CHARSET=utf-8:" +
      profileval?.firstName +
      " " +
      profileval?.lastName +
      "\nORG;CHARSET=utf-8:" +
      profileval?.companyName +
      "\nTITLE;CHARSET=utf-8:" +
      profileval?.designation +
      "\nEMAIL;TYPE=work:" +
      profileval?.email +
      "\nTEL;PREF;WORK:" +
      profileval?.mobileno +
      "\nTEL;WORK:" +
      profileval?.mobileno +
      "\nNOTE:" +
      profileval?.services + 
      "\nPHOTO;TYPE=JPEG;VALUE=URI:" +
      profileval?.imageurl +
      "\nURL:" +
      profileval?.website +
      "\nEND:VCARD";

    // var blob = new Blob([vcard], { type: "text/vcard" });
    // var url = URL.createObjectURL(blob);

    const blob = new Blob([vcard], { type: 'text/vcard' });
    const url = window.URL.createObjectURL(blob);



    setsavecontact(url);



     const newLink = document.createElement('a');
     newLink.href = url;

     newLink.download = profileval?.firstName + '.vcf';
     newLink.textContent = profileval?.firstName;
     document.body.appendChild(newLink);


     newLink.click();

     // Remove the link after triggering the download
    document.body.removeChild(newLink);
    
    // Revoke the object URL
    window.URL.revokeObjectURL(url);



    // const newLink = document.createElement("a");
    // newLink.download = contact.name + ".vcf";
    // newLink.textContent = contact.name;
    // newLink.href = url;

    // newLink.click();

    dispatch(setLoading(false));


    // const shatedata = {
    //   title: "New Contacts",
    //   text: "Save contacts",
    //   files: [
    //     new File([blob], "newcontact.vcf", {
    //       type: "text/vcard",
    //     }),
    //   ],
    // };

    // if (navigator.share && navigator.canShare(shatedata)) {
    //   navigator
    //     .share({
    //       title: "New Contacts",
    //       text: "Save contacts",
    //       files: [
    //         new File([blob], "newcontact.vcf", {
    //           type: "text/vcard",
    //         }),
    //       ],
    //     })
    //     .then(() => {});
    // } else {
    //   const newLink = document.createElement("a");
    //   newLink.download = profileval?.firstName + ".vcf";
    //   newLink.textContent = profileval?.firstName;
    //   newLink.href = url;

    //   newLink.click();

    //   // window.close();
    // }





  };

  const [shorturl, setShorturl] = useState();


  const BusinessProfileData = () => {
    let wametext = `https://wa.me/${profileval?.mobileno}?text=Hello ${profileval?.firstName}%0 I Have come across your profile through Know_Me App`;
    let altname = `Ascent Know_Me ${profileval?.firstName} ${profileval?.lastName}`;

    return (
      <div class="flex m-10 flex-col  justify-between items-center rounded-md relative shadow-lg w-full  lg:w-1/3 overflow-hidden z-30 md:p-10  bg-white p-2">
        {/* <div className="h-[300px]  bg-indigo-500 absolute w-full z-30 -m-10"></div> */}
        <div className="h-[300px] bg-white shadow-xl shadow-indigo-50 absolute w-full z-30 -m-10 pt-4">
        <img
            // src="https://res.cloudinary.com/sanketg/image/upload/v1685304129/TallyLogo23_vkyacc.jpg"
            src={profileval?.imageurl}
            alt={altname}              //"know_me profile"
            // class="imgclass brightness-125"
            class=" brightness-125 object-contain rounded-xl w-full h-full  object-center p-2"

            

          />

          </div>        
        <div className=" w-full flex h-[250px]">

        {/* <div className="h-14 bg-white absolute w-14 z-50 -m-10 justify-end flex items-end">
        <img
            // src="https://res.cloudinary.com/sanketg/image/upload/v1685304129/TallyLogo23_vkyacc.jpg"
            src={profileval?.imageurl}
            alt="know_me profile"
            // class="imgclass brightness-125"
            class=" brightness-125 object-fit w-full h-full  shadow-xl bg-transparent object-center"

            

          />

        </div> */}

        </div>
        {/* <div class="profileimage relative z-40  mt-2"> */}


 
        <div class="mt-5 z-50 w-[90%]  justify-center  ">
        <img
            // src="https://res.cloudinary.com/sanketg/image/upload/v1685304129/TallyLogo23_vkyacc.jpg"
            src={profileval?.imageurl}
            alt={altname}              //"know_me profile"
            // class="imgclass brightness-125"
            class=" brightness-125 object-contain rounded-xl w-full h-[300px]   bg-transparent object-center shadow-xl hidden"

            

          />
          </div>


        
        

        {roll === "business" && <p className="mt-4 uppercase text-center   bg-indigo-500 shadow-md w-full p-2 text-white">Business Profile</p>    }
        

        <div class="workdetails mt-5">
          <span class="text-xl text-indigo-500 mt-4 underline-offset-2 font-bold uppercase text-center">
            {profileval?.companyName}
          </span>
          
          {profileval?.address && <div class="mt-2 flex">
      

      <span class="text-normal text-center">Address : {profileval?.address}</span>
    </div>}          


    {profileval?.gmap && <div className=" flex flex-col gap-2 items-center mt-2 p-2"><a
            href={profileval?.gmap}
            target="_blank"
            class="link bg-[#ffffff]"
          >
            {/* <i class="ri-map-pin-fill"></i> */}
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="105" height="105" viewBox="0 0 48 48">
<path fill="#48b564" d="M35.76,26.36h0.01c0,0-3.77,5.53-6.94,9.64c-2.74,3.55-3.54,6.59-3.77,8.06	C24.97,44.6,24.53,45,24,45s-0.97-0.4-1.06-0.94c-0.23-1.47-1.03-4.51-3.77-8.06c-0.42-0.55-0.85-1.12-1.28-1.7L28.24,22l8.33-9.88	C37.49,14.05,38,16.21,38,18.5C38,21.4,37.17,24.09,35.76,26.36z"></path><path fill="#fcc60e" d="M28.24,22L17.89,34.3c-2.82-3.78-5.66-7.94-5.66-7.94h0.01c-0.3-0.48-0.57-0.97-0.8-1.48L19.76,15	c-0.79,0.95-1.26,2.17-1.26,3.5c0,3.04,2.46,5.5,5.5,5.5C25.71,24,27.24,23.22,28.24,22z"></path><path fill="#2c85eb" d="M28.4,4.74l-8.57,10.18L13.27,9.2C15.83,6.02,19.69,4,24,4C25.54,4,27.02,4.26,28.4,4.74z"></path><path fill="#ed5748" d="M19.83,14.92L19.76,15l-8.32,9.88C10.52,22.95,10,20.79,10,18.5c0-3.54,1.23-6.79,3.27-9.3	L19.83,14.92z"></path><path fill="#5695f6" d="M28.24,22c0.79-0.95,1.26-2.17,1.26-3.5c0-3.04-2.46-5.5-5.5-5.5c-1.71,0-3.24,0.78-4.24,2L28.4,4.74	c3.59,1.22,6.53,3.91,8.17,7.38L28.24,22z"></path>
</svg>           
          </a>
          <span className="text-black text-xs font-bold">Google Map for direction</span>
          </div>}



        </div>

        <div class="webdetails mt-1">
          {profileval?.website && <div class="text-center">
                  <a href={profileval?.website} class="weburl text-base">
            Website : {profileval?.website}
            </a>
          </div>}

          {profileval?.email && <div class="text-center mt-1 text-base">
      
            <span class="userwork">Email : {profileval?.email}</span>
          </div>}

          {profileval?.mobileno && <span class="Mobile font-semibold mt-2 mb-2 text-center">
            Mobile : {profileval?.mobileno}
          </span>}


          <span class="mt-2 px-4 bg-indigo-500 text-white text-center h-0.5 w-full "></span>     

{/* google Review comp */}
{profileval?.wechat && <div class="mt-5 text-base flex flex-col justify-center items-center">
      <a
            href={profileval?.wechat}
            target="_blank"
            class="link" 
          >
           <svg  width="90px" height="90px" viewBox="-3 0 262 262" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027" fill="#4285F4"></path><path d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1" fill="#34A853"></path><path d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782" fill="#FBBC05"></path><path d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251" fill="#EB4335"></path></g></svg>
           
          </a>
          <div className="gap-4 flex mt-2 mb-2">
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="rgba(255,215,0,1)"><path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path></svg>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="rgba(255,215,0,1)"><path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path></svg>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="rgba(255,215,0,1)"><path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path></svg>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="rgba(255,215,0,1)"><path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path></svg>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="rgba(255,215,0,1)"><path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path></svg>


           </div>
           <Link to={profileval?.wechat} className="w-full flex no-underline animate-pulse">
           <span class="p-3 bg-lime-500 text-white text-center w-full mt-2">Click to Review us on Google</span>
           </Link>



      <span class="mt-4 mb-2 px-4 bg-indigo-500 text-white text-center h-0.5 w-full "></span>            
    </div>}




          <span class="mt-2 px-4 p-2 bg-indigo-500 text-white text-center">Company Registration Details</span>



          {profileval?.gstin && <div class="flex w-full mt-2 text-base flex-col">
      
      <span class="userwork">GST Tin : {profileval?.gstin}</span>
      <span class="mt-2 px-4 bg-indigo-500 text-white text-center h-0.5"></span>

    </div>}

    {profileval?.pannumber && <div class="flex w-full mt-2 text-base flex-col">
      
      <span class="userwork">PAN Number : {profileval?.pannumber}</span>
      <span class="mt-2 px-4 bg-indigo-500 text-white text-center h-0.5"></span>      
    </div>}
    {profileval?.tannumber && <div class="flex w-full mt-2 text-base flex-col">
      
      <span class="userwork">TAN Number : {profileval?.tannumber}</span>
      <span class="mt-2 px-4 bg-indigo-500 text-white text-center h-0.5"></span>            
    </div>}

    {profileval?.udyam && <div class="flex w-full mt-2 text-base flex-col">
      
      <span class="userwork">Udyam Registration No : {profileval?.udyam}</span>
      <span class="mt-2 px-4 bg-indigo-500 text-white text-center h-0.5"></span>      
    </div>}

    {profileval?.fssai && <div class="flex w-full mt-2 text-base flex-col">
      
      <span class="userwork">FSSAI No : {profileval?.fssai}</span>
      <span class="mt-2 px-4 bg-indigo-500 text-white text-center h-0.5"></span>      
    </div>}


    {profileval?.cinnumber && <div class="flex w-full mt-2 text-base flex-col">

      <span class="userwork">Corporate Identity No : {profileval?.cinnumber}</span>
    </div>}



{/* Bank Details */}

{profileval?.bankdetails && <div class="mt-2 mb-2 text-base flex flex-col ">
  <span class="px-4 p-2 bg-indigo-500 text-white text-center">Company Bank Details</span>
      <span class="userwork mt-1 font-semibold text-center">{profileval?.bankdetails}</span>
    </div>}


    {profileval?.upiid && <div class=" mt-2 mb-2 text-base gap-2 flex flex-col text-center">

      <span class="px-4 bg-indigo-500 text-white">UPI ID</span>      
      <span class="userwork">{profileval?.upiid}</span>
      <div className="flex justify-center">
      <QRCode size={140}
                  value={`upi://pay?pa=${profileval?.upiid}`}
      ></QRCode>

        </div>
        <span class="userwork">scan to pay</span>
    </div>}
    {/* <span class="mt-2 px-4 bg-indigo-500 text-white text-center h-0.5"></span>       */}


    {profileval?.services && <div class="mt-2 text-base flex flex-col ">
  <span class="px-4 p-2 bg-indigo-500 text-white text-center">Company Services</span>
      <span class="userwork mt-2 text-center">{profileval?.services}</span>
      {/* <span class="mt-2 px-4 bg-indigo-500 text-white text-center h-0.5"></span>             */}
    </div>}


    {profileval?.catelogurl && <div class="webdetails mt-1 text-base gap-2 flex flex-col">

<span class="px-4 bg-indigo-500 text-white text-center p-2">Product Cateloge URL</span>      
<Link class="weburl text-center p-2 shadow-md" to={profileval?.catelogurl}>Click to view Cateloge</Link>

</div>}
<span class="mt-2 px-4 bg-indigo-500 text-white text-center h-0.5"></span>      



        </div>

        <div class="socialtabs mt-2  p-2 w-full items-center justify-center  
        ">



{/* Facebook */}

          {profileval?.facebook && <div className=" flex flex-col gap-2 items-center "><a
            href={profileval?.facebook}
            target="_blank"
            class="link bg-[#1877F2] shadow-lg"
          >
            <i class="ri-facebook-fill text-3xl"></i>
           
          </a>
          <span className="text-black text-xs">FB</span>
          </div>}

 {/* Youtube */}
 {profileval?.youtube && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.youtube}
            target="_blank"
            class="link bg-[#ff0000]"
          >
            <i class="ri-youtube-fill text-3xl"></i>
          </a>
          <span className="text-black text-xs">Youtube</span>
          </div>
                    }






{/* //Instagram */}

          {profileval?.instagram && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.instagram}
            target="_blank"
            class="link bg-[#E4405F]"
          >
            <i class="ri-instagram-fill text-3xl"></i>
           
          </a>
          <span className="text-black text-xs ">Insta</span>
          </div>}



{/* //linkdin */}
    
    
{profileval?.linkedin && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.linkedin}
            target="_blank"
            class="link bg-[#0a66c2]"
          >
            <i class="ri-linkedin-fill text-3xl"></i>
           
          </a>
          <span className="text-black text-xs">Linkedin</span>
          </div>}




{/* Social links */}
{/* X twitter */}

          {profileval?.socialx &&
          
          <div className=" flex flex-col gap-2 items-center "><a
            href={profileval?.socialx}
            target="_blank"
            class="link bg-[#000000]"
          >
            {/* <i class="ri-twitter-x-fil text-white">x</i> */}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor"><path d="M10.4883 14.651L15.25 21H22.25L14.3917 10.5223L20.9308 3H18.2808L13.1643 8.88578L8.75 3H1.75L9.26086 13.0145L2.31915 21H4.96917L10.4883 14.651ZM16.25 19L5.75 5H7.75L18.25 19H16.25Z"></path></svg>
          </a>
          <span className="text-black text-xs">Twitter-X</span>
          </div>
          
          }

{/* Gmap */}


          {/* <a
            href={savecontact}
            class="link ring-1 ring-[#000000]"
            onClick={SavetoContact}
            target="_blank"
            // download={savecontact}
            download
          >
            <i class="ri-file-download-line bg-red-800"></i>
          </a>
 */}

        </div>

{/* //next line  */}
<div class="socialtabs mt-2">

 


{/* SnapChat */}
 {profileval?.snapchat && <div className=" flex flex-col gap-2 items-center "><a
            href={profileval?.snapchat}
            target="_blank"
            class="link bg-[#FFFC00]"
          >
            <i class="ri-snapchat-fill"></i>
          </a>
          <span className="text-black text-xs">Snapchat</span>
          </div>
                    }

{/* Tiktok */}

          {profileval?.tiktok && 
          <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.tiktok}
            target="_blank"
            class="link bg-[#010101]" 
          >
            <i class="ri-tiktok-fill"></i>
          </a>
          <span className="text-black text-xs">Tiktok</span>
          </div>}

{/* Google Review
{profileval?.wechat && 
          <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.wechat}
            target="_blank"
            class="link bg-[#09B83E]" 
          >
            <i class="ri-google-fill"></i>
          </a>
          <span className="text-black text-xs">Google Review</span>
          </div>} */}

</div>
<span class="px-4 bg-indigo-500 text-white text-center h-0.5 w-full"></span>      


        <div class="cta flex w-full  items-center justify-center">
          <a
            href={savecontact}
            class="bg-blue-600 shadow-xl p-3 px-8 text-white  rounded-md justify-center flex flex-row gap-2 items-center  font-light"
            onClick={SavetoContact}
            target="_blank"
            // download={savecontact}
            download
          >
            Save to contacts
            <i class="ri-file-download-line animate-bounce"></i>
          </a>


        </div>



        <div class="cta flex w-full p-2">
          <a href={"tel:" + profileval.mobileno} class="bg-blue-600 p-2 w-1/2 text-white rounded-md justify-center flex flex-row gap-2 items-center no-underline">
            Call
            <i class="ri-phone-fill animate-pulse"></i>
          </a>{" "}
          <a
            href={`https://wa.me/${profileval?.mobileno}?text=Hello ${profileval?.firstName}  We have come across your profile through Know_Me App`}
            class="bg-green-600 p-2 w-1/2 text-white rounded-md justify-center flex flex-row gap-2 items-center no-underline"
          >
            WhatApp<i class="ri-whatsapp-fill animate-pulse"></i>
          </a>{" "}
        </div>
        <div class="footer">
          <a href="#" class="create">
            <span>Scan Qr code</span>
            <i class="ri-arrow-down-line animate-bounce"></i>
          </a>

            
          <span class="poweredby text-center flex items-center justify-center w-full text-blue-700">
            <a href="www.ascentnx.dev" className="no-underline text-black p-2">
            Powered by | Ascent Next | Since 2010
            </a>
          </span>
        </div>
      </div>
    );
  };

  const EmailSignature = ()=>{

    const signatureRef = useRef(null);
  const [copied, setCopied] = useState(false);

   
  const handleCopy = () => {
    const signatureHtml = signatureRef.current.innerHTML;
    const blob = new Blob([signatureHtml], { type: 'text/html' });
    const clipboardItem = new ClipboardItem({ 'text/html': blob });
    
    navigator.clipboard.write([clipboardItem])
      .then(() => {
        setCopied(true);
        alert('Signature Copied ');
           setTimeout(() => setCopied(false), 3000);
        
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
        alert('Failed to copy = Please try again.');
      });
  };


    return (
      <div className="bg-gray-50 p-5 rounded-lg mb-5">
      <h2 className="text-lg font-semibold mb-4 text-gray-700 text-center">Email Signature Preview</h2>
      
      
      <div className="border p-4 rounded bg-white mb-4">
        <div ref={signatureRef} >
        <table cellPadding="0" cellSpacing="0" style={{ maxWidth: '600px', fontFamily: 'Arial, sans-serif', backgroundColor:"#ffffff", borderTop: '1px solid', padding: '15px', borderColor:"gray", borderLeft:"none", borderRight:"none" }}>
                <tbody >
                  <tr>
                    <td valign="center" style={{ padding: '15px 15px 15px 15px', marginTop:"10" }}>
                      <img 
                        src={profileval?.imageurl} 
                        alt="Profile" 
                        style={{ maxWidth: '135px', maxHeight: '135px',width: '135px', height: '135px', borderRadius: '20px', objectFit:"contain", justifyContent:"center", alignItems:"center", display:"block" }} 
                      />
                      {/* <p style={{padding:"5px"}}></p>
                      <p >
                      <a href={process.env.REACT_APP_DOMAIN + qrloc.pathname} style={{   textAlign:"center", width: "120px", justifyItems:"center" , display: "block", margin:"auto", color:"#6466f1"}}>
                        View Profile </a>
                   
                      </p> */}
                      </td>
                    <td valign="top" style={{ borderLeft: '3px solid', paddingLeft: '15px', borderColor:"#6466f1" }}>
                      <table cellPadding="0" cellSpacing="0" style={{border:"none"}}>
                        <tbody>
                          <tr>
                            <td>
                              <p style={{ margin: '0 0 5px 0', fontSize: '20px', color: '#000000', fontWeight: 'bold' }}>
                                {profileval?.firstName} {profileval?.lastName}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            {profileval?.designation &&                      
                            
                             <td>
                              <div>
                              <p style={{ margin: '0 0 5px 0', fontSize: '14px', color: '#444444' }}>
                                {profileval?.designation} </p>
                                </div>
                            </td>}
                            
                          </tr>
                          <tr>
                          {profileval?.companyName &&
                            <td>
                              <p style={{ margin: '0 0 5px 0', fontSize: '15px', color:"gray", fontWeight:"bold" }}>
                                {profileval?.companyName}
                              </p>
                            </td>}
                            
                          </tr>

                          <tr>
                            <td>
                            {
                            profileval?.mobileno &&  

                            
                              <p style={{ margin: '0 0 5px 0', fontSize: '14px' , fontWeight: 'bold', justifyContent:"center", alignItems:"center", display: "block"}}>

                                <span style={{ fontWeight: 'bold' }}>Phone : </span> {profileval?.mobileno}
                                
                              </p>
                            }
                            {profileval?.email && 
                              <p style={{ margin: '0 0 5px 0', fontSize: '12px' }}>
                                    <span style={{ fontWeight: 'bold' }}>Email : </span> <a href={`mailto:${profileval?.email}`} style={{ color: '#1a73e8', textDecoration: 'none' }}>{profileval?.email}</a>
                              </p>
                            }
                            {profileval?.website && 
                                <p style={{ margin: '0 0 5px 0', fontSize: '12px' }}>
                                     <span style={{ fontWeight: 'bold' }}>Website : </span> <a href={`${profileval?.website}`} style={{ color: '#1a73e8', textDecoration: 'none' }}>{profileval?.website}</a>
                               </p>
                          
                            }
   {profileval?.wechat && 
                      <div style={{  marginTop:"5px", marginBottom:"5px", alignItems:"center", justifyItems:"center", display: "flex"}}>
                                <a href={`${profileval?.wechat}`} style={{  textDecoration:"none",alignItems:"center", justifyItems:"center", display: "flex" }}>
                                     <img 
                                       src={"https://res.cloudinary.com/sanketg/image/upload/v1741419908/ICONS/icons8-google-480_n5cqvs.png"}
                                       crossOrigin="anonymous"
                                       style={{ maxWidth: '20px', maxHeight: '20px' }} 

                     />
                                     <span style={{ fontWeight: 'bold', padding:"3px", fontSize:"10px",}}></span> <a href={`${profileval?.wechat}`} style={{ color: '#1a73e8', textDecoration: 'none' }}>Review us on google</a>
                               </a>
                               </div>
                            }
                  

                            
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingTop: '5px' }}>
                              <p style={{ borderTop: '2px solid #dddddd', borderColor:"#6466f1", padding:"2px" }}></p>
                            


                            {profileval?.youtube && <a
            href={profileval?.youtube}
            target="_blank"
            style={{ marginRight: '10px', textDecoration: 'none' }}
          >
           <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741177971/ICONS/vecteezy_set-of-social-media-icon-in-round-bakground__kwys2j.jpg"}
            crossOrigin="anonymous"
            style={{ maxWidth: '40px', maxHeight: '40px' }} 

                     />
          </a>
          
                    }

{/* Facebook */}

{profileval?.facebook && <a
            href={profileval?.facebook}
            target="_blank"
            // class="link text-[#1877F2] no-underline"
            style={{ marginRight: '10px', textDecoration: 'none' }}       
          >
            <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741177971/ICONS/facebook_fftyns.jpg"}
            crossOrigin="anonymous"
            style={{ maxWidth: '40px', maxHeight: '40px' }} 
            

          />
           
          </a>
          }



{/* //Instagram */}

          {profileval?.instagram && <a
            href={profileval?.instagram}
            target="_blank"
            // class="link text-[#E4405F] no-underline"
            style={{ marginRight: '10px', textDecoration: 'none' }}         
          >
           <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741177971/ICONS/Instagram_l4cqhm.jpg"}
            crossOrigin="anonymous"
            style={{ maxWidth: '40px', maxHeight: '40px' }} 

            

          />
           
          </a>
          }



{/* //linkdin */}
    
    
{profileval?.linkedin && <a
            href={profileval?.linkedin}
            target="_blank"
            // class="link text-[#0a66c2]"
            style={{ marginRight: '10px', textDecoration: 'none' }}       
          >
            <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741177971/ICONS/linkedin_f3awxu.jpg"}
            crossOrigin="anonymous"
            style={{ maxWidth: '40px', maxHeight: '40px' }} 

            

          />
           
          </a>
          }




{/* Social links */}
{/* X twitter */}
          {profileval?.socialx && <a
            href={profileval?.socialx}
            target="_blank"
            style={{ marginRight: '10px', textDecoration: 'none' }}
          >
           <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741177970/ICONS/133590707_Social-Media-Logo-collection-CIRCLE-2023_lei7qg.jpg"}
            crossOrigin="anonymous"
            style={{ maxWidth: '40px', maxHeight: '40px' }} 

            

          />

          </a>
          }

{/* Gmap */}
{profileval?.gmap && <a
            href={profileval?.gmap}
            target="_blank"
            style={{ marginRight: '10px', textDecoration: 'none' }}
          >
         <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741179256/ICONS/loc_tsh3wo.jpg"}
            crossOrigin="anonymous"
            style={{ maxWidth: '40px', maxHeight: '40px' }} 

            

          />

          </a>
          }

                     
                             
                           



                              {/* <a href={`https://`} style={{ marginRight: '10px', textDecoration: 'none' }}>
                                <img 
                                  src="https://cdnjs.cloudflare.com/ajax/libs/simple-icons/7.18.0/linkedin.svg" 
                                  alt="LinkedIn" 
                                  style={{ width: '20px', height: '20px' }} 
                                />
                              </a>
                              <a href={`https://`} style={{ textDecoration: 'none' }}>
                                <img 
                                  src="https://cdnjs.cloudflare.com/ajax/libs/simple-icons/7.18.0/twitter.svg" 
                                  alt="Twitter" 
                                  style={{ width: '20px', height: '20px' }} 
                                />
                              </a>
 */}


                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <i style={{ margin: '0 0 10px 0', fontSize: '12px', color: 'gray', fontFamily:"cursive" }}>
                                <a href="https://www.ascentindia.dev/contact">Email sign powered by - ascent software solutions</a>
                                
                              </i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p style={{maxWidth: "550px", justifyContent: "center", display:"block", textAlign:"center", fontWeight:"bold", padding:"4px", backgroundColor:"#2663eb", color:"#ffffff"}}>                      <a href={process.env.REACT_APP_DOMAIN + qrloc.pathname} style={{   textAlign:"center", maxWidth: "500x", justifyItems:"center" , display: "block", margin:"auto", color:"#ffffff", fontWeight:"lighter" }}>
                        Check out my digital business profile </a>
</p>              
              {/* <p style={{maxWidth: "500px", justifyContent: "center", display:"block", textAlign:"center", fontSize:"14px" ,fontFamily:"monospace", padding:"2px"}}>{profileval?.services}</p> */}
     {/* <p style={{padding:"5px"}}></p> */}
                      {/* <p >
                      <a href={process.env.REACT_APP_DOMAIN + qrloc.pathname} style={{   textAlign:"center", maxWidth: "500x", justifyItems:"center" , display: "block", margin:"auto", color:"#6466f1"}}>
                        Check out my digital business profile </a>
                   
                      </p> */}
                      <div style={{maxWidth: "550px", justifyContent: "center", display:"block",  fontSize:"10px" , padding:"2px", backgroundColor: "lightcyan"}}>
                      <p style={{textAlign:"start", fontSize:"12px" , padding:"2px", fontWeight:"bold"}}>Disclaimer :</p>
                      <span>This email and any attachments are intended for the recipient(s) specified in the message only and may contain confidential or privileged information. If you are not the intended recipient, please notify the sender immediately and delete this email from your system. Any unauthorized review, use, disclosure, or distribution is prohibited.</span>
                      <br></br>
                      <span style={{marginTop:"2px"}}>The contents of this email are not legally binding unless expressly stated. Please consider the environment before printing this email.
                       </span>
                       </div>

              </div>
              </div>
  
        <button onClick={handleCopy} 
        className="flax items-center w-full bg-indigo-500 text-white border-none">
          Copy Email Signature
        </button>
      </div>
    );
    
  }
  const EmailSignature2 = () => {
    return (
      <div  className="Email_Sign shadow-2xl   bg-white shadow-indigo-400 ">

                <div className="flex flex-col ">
                <span class="px-4 p-2 bg-indigo-500 text-white text-center">Email Signature</span>
                </div>


            
              <div className="flex items-center  w-full  border h-full gap-2 p-3">
                <div className="flex flex-col justify-center items-center p-2 gap-3">
                  <div className="justify-center items-centre bg-transparent ">
                  <img 
            src={profileval?.imageurl}
            crossOrigin="anonymous"
            alt={"AscentKnowMe_" + profileval?.firstName +" _ "+ profileval?.lastName}
            
            // class="imgclass brightness-125"
            // class="brightness-125 object-contain  rounded-full max-h-24 max-w-lg object-center "
            style={{width: 32, height: 32,  }}

            

          />
          {/* <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.youtube}
            target="_blank"
            class="link text-black no-underline  "
          >
               <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741177971/ICONS/vecteezy_set-of-social-media-icon-in-round-bakground__kwys2j.jpg"}
            crossOrigin="anonymous"
           
            // class="imgclass brightness-125"
            class="h-10 w-10 object-cover"

            

          />
          </a>
          </div> */}
                  </div>
                  <div><a href={process.env.REACT_APP_DOMAIN + qrloc.pathname} class="text-black ">
            
                  <span className="text-sm font-semibold">Check Profile</span>

          </a>{" "}</div>
                </div>
                <div className="border-r border-x-2 bg-lime-500  h-[220px] flex flex-col ">
                  <p className="h-full w-[1.5px]"></p>
                </div>

              <div className=" bg-lime-500 h-full mt-3 flex flex-col "/>

                <div className="flex flex-col items-start m-2 w-full p-2">
                <p className="items-start justify-start text-base font-semibold w-full mb-2 ">{profileval?.firstName +" "+ profileval?.lastName}</p>                                
                <p className="items-start justify-start italic font-black  w-full mb-2 ">{profileval?.designation}</p>

                <div class="flex  flex-col w-full gap-1">
          <a href={"tel:" + profileval?.mobileno} class="font-semibold text-black no-underline flex justify-start items-center text-start gap-2">
            
            
          <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741179256/ICONS/phone_vjk726.jpg"}
            crossOrigin="anonymous"
           class="h-8 w-8 object-cover brightness-125"

                     />
            <span>{profileval?.mobileno}</span>
          </a>{" "}

          <a href={"mailto:" + profileval?.email} class=" text-black no-underline flex justify-start items-center text-start gap-2">
            
          <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741179256/ICONS/Email_x25ede.jpg"}
            crossOrigin="anonymous"
           class="h-8 w-8 object-cover brightness-125"

                     />


            <span>{profileval?.email}</span>
          </a>{" "}

          <a href={profileval?.website} class=" text-black no-underline flex justify-start items-center text-start gap-2">
            
          <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741179257/ICONS/website_tzd0cj.jpg"}
            crossOrigin="anonymous"
           class="h-8 w-8 object-cover brightness-125"

                     />
            <span>{profileval?.website}</span>
          </a>{" "}
        </div>

        <div className=" bg-lime-500 h-1 w-full mt-3 flex flex-col "/>
<div className="social  flex gap-3 mt-4 ">

{profileval?.youtube && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.youtube}
            target="_blank"
            class="link text-black no-underline  "
          >
           <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741177971/ICONS/vecteezy_set-of-social-media-icon-in-round-bakground__kwys2j.jpg"}
            crossOrigin="anonymous"
           class="h-10 w-10 object-cover brightness-125"

                     />
          </a>
          </div>
                    }

{/* Facebook */}

{profileval?.facebook && <div className=" flex flex-col gap-2 items-center "><a
            href={profileval?.facebook}
            target="_blank"
            // class="link text-[#1877F2] no-underline"
            class="link text-black no-underline"            
          >
            <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741177971/ICONS/facebook_fftyns.jpg"}
            crossOrigin="anonymous"
           class="h-10 w-10 object-cover brightness-125"

            

          />
           
          </a>
          </div>}



{/* //Instagram */}

          {profileval?.instagram && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.instagram}
            target="_blank"
            // class="link text-[#E4405F] no-underline"
            class="link text-black no-underline"            
          >
           <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741177971/ICONS/Instagram_l4cqhm.jpg"}
            crossOrigin="anonymous"
           class="h-10 w-10 object-cover brightness-125"

            

          />
           
          </a>
          </div>}



{/* //linkdin */}
    
    
{profileval?.linkedin && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.linkedin}
            target="_blank"
            // class="link text-[#0a66c2]"
            class="link text-black"            
          >
            <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741177971/ICONS/linkedin_f3awxu.jpg"}
            crossOrigin="anonymous"
           class="h-10 w-10 object-cover brightness-125"

            

          />
           
          </a>
          </div>}




{/* Social links */}
{/* X twitter */}
          {profileval?.socialx && <div className=" flex flex-col gap-2 items-center hidden"><a
            href={profileval?.socialx}
            target="_blank"
            class="link text-[#000000] no-underline"
          >
           <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741177970/ICONS/133590707_Social-Media-Logo-collection-CIRCLE-2023_lei7qg.jpg"}
            crossOrigin="anonymous"
           class="h-10 w-10 object-cover brightness-125"

            

          />

          </a>
          </div>}

{/* Gmap */}
{profileval?.gmap && <div className=" flex flex-col items-center"><a
            href={profileval?.gmap}
            target="_blank"
            class="link text-black no-underline"
          >
         <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741179256/ICONS/loc_tsh3wo.jpg"}
            crossOrigin="anonymous"
           class="h-10 w-10 object-cover brightness-125"

            

          />

          </a>
          </div>}



</div>

                </div>


                {/* <div className="flex items-center w-full justify-center ">
              <p className=" text-lime-500 text-sm underline underline-offset-2 " >
                {profileval?.companyName}</p>
            </div> */}



              </div>



              </div>

    )
  }
  const ProfileData = () => {
    let wametext = `https://wa.me/${profileval?.mobileno}?text=Hello ${profileval?.firstName}%0 I Have come across your profile through Know_Me App`;
    let altname = `Ascent Know_Me ${profileval?.firstName} ${profileval?.lastName}`;

    return (
      <div class="flex m-10 flex-col  justify-between items-center rounded-md relative shadow-lg w-full  lg:w-1/3 overflow-hidden z-30 md:p-10  bg-white ">
        <div className="h-[300px] bg-indigo-500  absolute w-full z-30 -m-10 "></div>
        <div class="mt-5 z-50 w-[85%] h-70 flex justify-center p-2 bg-transparent items-center ">

          <img
            // src="https://res.cloudinary.com/sanketg/image/upload/v1685304129/TallyLogo23_vkyacc.jpg"
            src={profileval?.imageurl}
            alt={altname}              //"know_me profile"
            // class="imgclass brightness-125"
            class=" brightness-125 object-cover rounded-3xl w-full h-full  shadow-xl  bg-transparent object-center"

            

          />

        
        
        </div>
        {/* {roll === "business" && <p className="uppercase">{roll} Profile</p>    } */}
        
        {roll === "user" && <p className="mt-10 uppercase text-center   bg-indigo-500 shadow-md w-full p-2 text-white ">Profile</p>    }


        <div class="workdetails ">
          <span class="username">
            {profileval?.firstName} {profileval?.lastName}
          </span>
          
          <span class="font-light text-sm">{profileval?.designation}</span>
          
          {profileval?.designation && <span class="companyname">{profileval?.companyName}</span>}
          {profileval?.mobileno && <span class="Mobile font-semibold mt-1">
            Mobile : {profileval?.mobileno}
          </span>}
        </div>

        <div class="webdetails">
          {profileval?.website && <div class="website justify-center">
                  <a href={profileval?.website} class="weburl">
            Website : {profileval?.website}
            </a>
          </div>}

          {profileval?.email && <div class="email mt-2 justify-center">
      
            <span class="userwork">Email : {profileval?.email}</span>
          </div>}


          {profileval?.services && <div class="mt-2 p-2 text-base flex flex-col h-full">
  <span class="px-4 p-2 bg-indigo-500 text-white text-center">Services</span>
      <span class="m-2 mb-2 text-xl text-center">{profileval?.services}</span>
      <span class="mt-2 px-4 bg-indigo-500 text-white text-center h-0.5"></span>            

    </div>}


{/* google Review comp */}
    {profileval?.wechat && <div class="mt-5 text-base flex flex-col justify-center items-center">
      <a
            href={profileval?.wechat}
            target="_blank"
            class="link" 
          >
           <svg  width="105px" height="105px" viewBox="-3 0 262 262" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027" fill="#4285F4"></path><path d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1" fill="#34A853"></path><path d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782" fill="#FBBC05"></path><path d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251" fill="#EB4335"></path></g></svg>
           
          </a>
          <div className="gap-4 flex mt-2 mb-2">
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="rgba(255,215,0,1)"><path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path></svg>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="rgba(255,215,0,1)"><path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path></svg>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="rgba(255,215,0,1)"><path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path></svg>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="rgba(255,215,0,1)"><path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path></svg>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="rgba(255,215,0,1)"><path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path></svg>


           </div>
           <Link to={profileval?.wechat} className="w-full flex no-underline animate-pulse">
           <span class="p-3 bg-lime-500 text-white text-center w-full mt-2">Click to Review us on Google</span>
           </Link>



      <span class="mt-2 px-4 bg-indigo-500 text-white text-center h-0.5 w-full "></span>            
    </div>}



        </div>

        <div class="socialtabs mt-5  p-2 w-full items-center justify-center  
        ">
          
{/* Facebook */}

          {profileval?.facebook && <div className=" flex flex-col gap-2 items-center "><a
            href={profileval?.facebook}
            target="_blank"
            class="link bg-[#1877F2] shadow-lg "
          >
            <i class="ri-facebook-fill text-3xl" ></i>
           
          </a>
          <span className="text-black text-xs font-semibold">FB</span>
          </div>}



{/* //Instagram */}

          {profileval?.instagram && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.instagram}
            target="_blank"
            class="link bg-[#E4405F]"
          >
            <i class="ri-instagram-fill text-3xl"></i>
           
          </a>
          <span className="text-black text-xs font-semibold">Insta</span>
          </div>}



{/* //linkdin */}
    
    
{profileval?.linkedin && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.linkedin}
            target="_blank"
            class="link bg-[#0a66c2]"
          >
            <i class="ri-linkedin-fill text-3xl"></i>
           
          </a>
          <span className="text-black text-xs font-semibold">Linkedin</span>
          </div>}




{/* Social links */}
{/* X twitter */}
          {profileval?.socialx && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.socialx}
            target="_blank"
            class="link bg-[#000000]"
          >
            {/* <i class="ri-twitter-x-fil text-white">x</i> */}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor"><path d="M10.4883 14.651L15.25 21H22.25L14.3917 10.5223L20.9308 3H18.2808L13.1643 8.88578L8.75 3H1.75L9.26086 13.0145L2.31915 21H4.96917L10.4883 14.651ZM16.25 19L5.75 5H7.75L18.25 19H16.25Z"></path></svg>
          </a>
          <span className="text-black text-xs font-semibold">Twitter-X</span>
          </div>}



          {/* <a
            href={savecontact}
            class="link ring-1 ring-[#000000]"
            onClick={SavetoContact}
            target="_blank"
            // download={savecontact}
            download
          >
            <i class="ri-file-download-line bg-red-800"></i>
          </a>
 */}

        </div>

{/* //next line  */}
<div class="socialtabs mt-5 ">

  {/* Gmap */}
{profileval?.gmap && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.gmap}
            target="_blank"
            class="link bg-white shadow-lg shadow-gray-300"
          >
            {/* <i class="ri-map-pin-fill"></i> */}
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
<path fill="#48b564" d="M35.76,26.36h0.01c0,0-3.77,5.53-6.94,9.64c-2.74,3.55-3.54,6.59-3.77,8.06	C24.97,44.6,24.53,45,24,45s-0.97-0.4-1.06-0.94c-0.23-1.47-1.03-4.51-3.77-8.06c-0.42-0.55-0.85-1.12-1.28-1.7L28.24,22l8.33-9.88	C37.49,14.05,38,16.21,38,18.5C38,21.4,37.17,24.09,35.76,26.36z"></path><path fill="#fcc60e" d="M28.24,22L17.89,34.3c-2.82-3.78-5.66-7.94-5.66-7.94h0.01c-0.3-0.48-0.57-0.97-0.8-1.48L19.76,15	c-0.79,0.95-1.26,2.17-1.26,3.5c0,3.04,2.46,5.5,5.5,5.5C25.71,24,27.24,23.22,28.24,22z"></path><path fill="#2c85eb" d="M28.4,4.74l-8.57,10.18L13.27,9.2C15.83,6.02,19.69,4,24,4C25.54,4,27.02,4.26,28.4,4.74z"></path><path fill="#ed5748" d="M19.83,14.92L19.76,15l-8.32,9.88C10.52,22.95,10,20.79,10,18.5c0-3.54,1.23-6.79,3.27-9.3	L19.83,14.92z"></path><path fill="#5695f6" d="M28.24,22c0.79-0.95,1.26-2.17,1.26-3.5c0-3.04-2.46-5.5-5.5-5.5c-1.71,0-3.24,0.78-4.24,2L28.4,4.74	c3.59,1.22,6.53,3.91,8.17,7.38L28.24,22z"></path>
</svg>           
          </a>
          <span className="text-black text-xs font-semibold">G-Map</span>
          </div>}


  {/* Youtube */}
  {profileval?.youtube && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.youtube}
            target="_blank"
            class="link bg-[#ff0000]"
          >
            <i class="ri-youtube-fill text-3xl"></i>
          </a>
          <span className="text-black text-xs font-semibold">Youtube</span>
          </div>
                    }



{/* SnapChat */}
 {profileval?.snapchat && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.snapchat}
            target="_blank"
            class="link bg-[#FFFC00]"
          >
            <i class="ri-snapchat-fill text-3xl"></i>
          </a>
          <span className="text-black text-xs font-semibold">Snapchat</span>
          </div>
                    }

{/* Tiktok */}

          {profileval?.tiktok && 
          <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.tiktok}
            target="_blank"
            class="link bg-[#010101]" 
          >
            <i class="ri-tiktok-fill text-3xl"></i>
          </a>
          <span className="text-black text-xs font-semibold">Tiktok</span>
          </div>}

{/* Google Review */}
{/* {profileval?.wechat && 
          <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.wechat}
            target="_blank"
            class="link bg-yellow-500 " 
          >
            <i class="ri-google-fill"></i>
          </a>
          <span className="text-black text-xs">Google Review</span>
          </div>} */}

</div>
<span class="mt-2 px-4 bg-indigo-500 text-white text-center h-0.5 w-full "></span>            

        <div class="cta flex w-full  items-center justify-center">
          <a
            href={savecontact}
            class="bg-blue-600 shadow-xl p-3 px-8 text-white  rounded-md justify-center flex flex-row gap-2 items-center  font-light"
            // onClick={SavetoContact}
            onClick={()=>{
              if (roll === "freemium") {
               alert("Need to Upgrade for Paid Subscription") 
              }
              else
              {
                SavetoContact()
              }
            }} 
            target="_blank"
            // download={savecontact}
            download
          >
            Save to contacts
            <i class="ri-file-download-line animate-bounce"></i>
          </a>


        </div>



        <div class="cta flex w-full p-2">
          <a href={"tel:" + profileval.mobileno} class="bg-blue-600 p-2 w-1/2 text-white rounded-md justify-center flex flex-row gap-2 items-center no-underline">
            Call
            <i class="ri-phone-fill animate-pulse"></i>
          </a>{" "}
          <a
            href={`https://wa.me/${profileval?.mobileno}?text=Hello ${profileval?.firstName}  We have come across your profile through Know_Me App`}
            class="bg-green-600 p-2 w-1/2 text-white rounded-md justify-center flex flex-row gap-2 items-center no-underline"
          >
            WhatApp<i class="ri-whatsapp-fill animate-pulse"></i>
          </a>{" "}
        </div>


        
        <div class="footer">
          <a href="#" class="create">
            <span>Scan Qr code</span>
            <i class="ri-arrow-down-line animate-bounce"></i>
          </a>

            
          <span class="poweredby text-center flex items-center justify-center w-full text-blue-700">
            <a href="www.ascentnx.dev" className="no-underline text-black p-2">
            Powered by | Ascent Next | Since 2010
            </a>
          </span>
        </div>
      </div>
    );
  };

  const Profile1 = () => {
    // console.log("env : ", process.env.REACT_APP_DOMAIN);
    let qrloc = useLocation();
    // const shortUrl = require("turl");
    // shortUrl
    //   .shorten(qrloc)
    //   .then((res) => {
    //     setShorturl(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    // shortUrl.short({ qrloc }, function (err, url) {
    //   console.log("url", url);
    //   setShorturl(url);
    // });

    const [usermobile, setusermobile] = useState("")
    const [userreference, setuserreference] = useState("")

    const whatsappMessage= `Hello this is *${profileval?.firstName + " " + profileval?.lastName + "*🤝%0A%0AReference " + "_"+userreference+"_" }%0A%0ACheck out my profile from Know_Me Digital Business Card @🌍%0A${process.env.REACT_APP_DOMAIN + qrloc.pathname} `

    const handleChange = (e) => {
      // console.log("mobile no of user ",e.target.value);
       setusermobile(e.target.value)
    }


    const handleChangeref = (e) => {
      // console.log("mobile no of user ",e.target.value);
       setuserreference(e.target.value)
    }

    const HandleWhatsAppLink = () => {

      const wurl = `https://wa.me/${usermobile}?source=multi&text=Hello this is *${profileval?.firstName + " " + profileval?.lastName}* 🤝%0A%0AReference _${userreference}_ %0A%0ACheck out my profile from%0A%0A*Know_Me Digital Business Card* %0A%0A@  ${process.env.REACT_APP_DOMAIN + qrloc.pathname}`
      const mwurl = `whatsapp://send?phone=${usermobile}&text=Hello this is *${profileval?.firstName + " " + profileval?.lastName}* 🤝%0A%0AReference _${userreference}_ %0A%0ACheck out my profile from%0A%0A*Know_Me Digital Business Card* %0A%0A@  ${process.env.REACT_APP_DOMAIN + qrloc.pathname}`

      window.open(mwurl, '_blank')
    }
  

    return (
      <div className="bg-neutral-100 w-screen">
        {!expdate && (
          <div class="flex flex-col justify-center items-center w-full p-2 bg-neutral-100 ">
            {/* {expdate ? <h1>Profile Expired</h1> : <h1>Not Expired</h1>} */}
            {/* <h1>Checking Expiry Date{profileval?.trialenddate}</h1> */}

            {roll === "business" ? <BusinessProfileData /> : <ProfileData />}

            <div className="qrData w-full  lg:w-1/3 flex  flex-col justify-center">
              
              <div ref={contentRef} className="Sharecarddetails shadow-2xl   bg-white shadow-indigo-400">
              <div className="flex items-center w-full justify-center bg-lime-500 h-[1px] shadow-md">
              <p className="text-white  text-sm hidden" >
                Save paper save trees</p>
            </div>

              <div className="justify-between flex items-center flex-row    border h-full ">
                <div className="flex flex-col justify-center items-center py-2 w-full  ">
                  <div className="justify-center items-center bg-gray-50 bg-transparent p-1 rounded-lg ">
                    {/* <p className="border-r border-x-2 bg-orange-500  h-[220px] flex flex-col ">234</p> */}
                  <img 
            // src="https://res.cloudinary.com/sanketg/image/upload/v1685304129/TallyLogo23_vkyacc.jpg"
            src={profileval?.imageurl}
            crossOrigin="anonymous"
            alt={"AscentKnowMe_" + profileval?.firstName +" _ "+ profileval?.lastName}
            
            // class="imgclass brightness-125"
            class="brightness-125 object-contain rounded-lg w-[100px] h-[110px] object-center "

            

          />
                  </div>
              {roll === "business" && 
                                <div className="flex w-full flex-col">
                                <h1 className="text-base font-semibold  mt-3 uppercase w-full text-center">    {profileval?.companyName}</h1>
                                <p className="items-start justify-start text-xs font-normal w-full text-center ">{profileval?.designation}</p>
              
                                </div>
              
              }

                  {roll !== "business" && 
                                <div className="flex w-full flex-col">
                                <h1 className="text-base font-semibold  mt-3 uppercase w-full text-center">    {profileval?.firstName} {profileval?.lastName}</h1>
                                <p className="items-start justify-start text-xs font-normal w-full text-center ">{profileval?.designation}</p>
              
                                </div>
              
              }



                </div>
                <div className="border-r border-x-2 bg-orange-500  h-[220px] flex flex-col ">
                  <p className="h-full w-[1.5px]"></p>
                </div>
                <div className="flex flex-col justify-center items-center m-2 ">
                <QRCode size={140}
                  value={process.env.REACT_APP_DOMAIN + qrloc.pathname}
                ></QRCode>
                <h1 className="text-xs font-thin">Scan to view profile</h1>

                </div>
                {/* <div className="flex items-center w-full justify-center ">
              <p className=" text-lime-500 text-sm underline underline-offset-2 " >
                {profileval?.companyName}</p>
            </div> */}



              </div>

              {roll !== "business" &&           
                <div className="flex items-center w-full justify-center bg-lime-500 p-1 shadow-md">
              <p className="text-white  text-sm " >
                Save paper save trees go green</p>
            </div>
              }



            {roll === "business" &&           
                <div className="flex items-center w-full justify-center bg-lime-500 p-1 shadow-md">
              <p className="text-white  text-sm " >
              Save paper save trees go green</p>
            </div>
              }



              </div>


            


              <div className="flex items-center w-full justify-center p-3  mt-2">
              <button className="p-2 px-10 bg-indigo-500 text-white border-0 rounded-md text-sm
              " onClick= {()=>{
                if (roll === "freemium") {
                 alert("Need to Upgrade for Paid Subscription") 
                }
                else
                {
                shareToWhatsAppClaud()
                }
              }} 
              >
                Download and share</button>
            </div>


              <div className="mt-10 gap-2 flex flex-col p-2 mb-5" >
                <h3 className="font-light text-sm">Enter Destination Mobile no with country code</h3>
                <input value={usermobile} type="number"  placeholder="Mobile no with Country Code" className="w-full p-2 text-black font-semibold text-2xl"  onChange={handleChange}></input>

                <h3 className="font-light text-sm ">Enter Reference </h3>
                <input value={userreference} placeholder="Reference of meeting" className="w-full p-2 text-black text-xl  mb-4"  onChange={handleChangeref}></input>
                <div className="flex w-full justify-center gap-2">
                <a
                target="_blank"
                // href={`https://wa.me/${usermobile}?text=Hello this is *${profileval?.firstName + " " + profileval?.lastName}* 🤝%0A%0AReference _${userreference}_ %0A%0ACheck out my profile from%0A%0A*Know_Me Digital Business Card* %0A%0A@  ${process.env.REACT_APP_DOMAIN + qrloc.pathname}  `}
                // href={`https://wa.me/${usermobile}?text=Hello this is *${profileval?.firstName + " " + profileval?.lastName}* 🤝%0A%0AReference _${userreference}_ %0A%0ACheck out my profile from%0A%0A*Know_Me Digital Business Card* %0A%0A@  ${process.env.REACT_APP_DOMAIN + qrloc.pathname}  &source=multi`}                
                // href={`https://api.whatsapp.com/send?phone=${usermobile}&text=Hello this is *${profileval?.firstName + " " + profileval?.lastName}* 🤝%0A%0AReference _${userreference}_ %0A%0ACheck out my profile from%0A%0A*Know_Me Digital Business Card* %0A%0A@  ${process.env.REACT_APP_DOMAIN + qrloc.pathname} &app=multi`}
                href={`whatsapp://send?phone=${usermobile}&text=Hello this is *${profileval?.firstName + " " + profileval?.lastName}* 🤝%0A%0AReference _${userreference}_ %0A%0ACheck out my profile from%0A%0A*Know_Me Digital Business Card* %0A%0A@  ${process.env.REACT_APP_DOMAIN + qrloc.pathname}`}

                  // href={`https://api.whatsapp.com/send?text=${whatsappMessage}`}
                class="bg-green-600 p-3  text-white rounded-md justify-center flex flex-row gap-2 items-center no-underline mb-5 w-[50%]"
              >
                 WhatApp Mobile<i class="ri-whatsapp-fill animate-pulse"></i>
              </a>{" "}

              <a
                target="_blank"
                // href={`https://wa.me/${usermobile}?text=Hello this is *${profileval?.firstName + " " + profileval?.lastName}* 🤝%0A%0AReference _${userreference}_ %0A%0ACheck out my profile from%0A%0A*Know_Me Digital Business Card* %0A%0A@  ${process.env.REACT_APP_DOMAIN + qrloc.pathname}  `}
                href={`https://wa.me/${usermobile}?text=Hello this is *${profileval?.firstName + " " + profileval?.lastName}* 🤝%0A%0AReference _${userreference}_ %0A%0ACheck out my profile from%0A%0A*Know_Me Digital Business Card* %0A%0A@  ${process.env.REACT_APP_DOMAIN + qrloc.pathname} `}                

                class="ring-1 ring-green-600 p-3  text-green-600 rounded-md justify-center flex flex-row gap-2 items-center no-underline mb-5 w-[50%]"
              >
                WhatApp Web <i class="ri-whatsapp-fill animate-pulse"></i>
              </a>{" "}

                  </div>


                  {/* <div className="flex w-full justify-center gap-2">
                <p className=" text-green-600 rounded-md justify-center flex flex-row gap-2 items-center no-underline w-[50%]">For web</p>
                <p className=" text-green-600 rounded-md justify-center flex flex-row gap-2 items-center no-underline w-[50%]">For web</p>
                  </div> */}



                {/* <a
                target="_blank"
                // href={`https://wa.me/${usermobile}?text=Hello this is *${profileval?.firstName + " " + profileval?.lastName}* 🤝%0A%0AReference _${userreference}_ %0A%0ACheck out my profile from%0A%0A*Know_Me Digital Business Card* %0A%0A@  ${process.env.REACT_APP_DOMAIN + qrloc.pathname}  `}
                // href={`https://wa.me/${usermobile}?text=Hello this is *${profileval?.firstName + " " + profileval?.lastName}* 🤝%0A%0AReference _${userreference}_ %0A%0ACheck out my profile from%0A%0A*Know_Me Digital Business Card* %0A%0A@  ${process.env.REACT_APP_DOMAIN + qrloc.pathname}  &source=multi`}                
                // href={`https://api.whatsapp.com/send?phone=${usermobile}&text=Hello this is *${profileval?.firstName + " " + profileval?.lastName}* 🤝%0A%0AReference _${userreference}_ %0A%0ACheck out my profile from%0A%0A*Know_Me Digital Business Card* %0A%0A@  ${process.env.REACT_APP_DOMAIN + qrloc.pathname} &app=multi`}
                href={`whatsapp://send?phone=${usermobile}&text=Hello this is *${profileval?.firstName + " " + profileval?.lastName}* 🤝%0A%0AReference _${userreference}_ %0A%0ACheck out my profile from%0A%0A*Know_Me Digital Business Card* %0A%0A@  ${process.env.REACT_APP_DOMAIN + qrloc.pathname}`}

                  // href={`https://api.whatsapp.com/send?text=${whatsappMessage}`}
                class="bg-green-600 p-3  text-white rounded-md justify-center flex flex-row gap-2 items-center no-underline mb-5"
              >
                Click to WhatApp Profile<i class="ri-whatsapp-fill animate-pulse"></i>
              </a>{" "} */}

              {/* <button
               className="bg-red-600 p-2  text-white rounded-md justify-center flex flex-row gap-2 items-center no-underline "
               onClick={HandleWhatsAppLink}>WhatApp Mobile Profile<i class="ri-whatsapp-fill animate-pulse"></i>
               </button> */}

            <span className="text-center text-xs"><a href="www.ascentnx.dev" className="no-underline text-black p-2">
            Powered by | Ascent Next | Since 2010
            </a></span>
            <span className="text-center text-xs"><a href="www.ascentnx.dev" className="no-underline text-black p-2">
            ❤️
            </a></span>


            <span className="text-center text-xs"><a href="www.ascentnx.dev" className="no-underline text-black p-2">
              Made in India 🙏
            </a></span>


              </div>
            </div>
            {/* {profileval?.firstName === "Sanket" && <div>
                <p>Order Booking</p>
              </div>} */}


{/* //-- Email Signature */}
<EmailSignature/>
<div  className="Email_Sign shadow-2xl   bg-white shadow-indigo-400 hidden">

                <div className="flex flex-col ">
                <span class="px-4 p-2 bg-indigo-500 text-white text-center">Email Signature</span>
                </div>


            
              <div className="flex items-center  w-full  border h-full gap-4 p-3">
                <div className="flex flex-col justify-center items-center p-2 gap-3">
                  <div className="justify-center items-centre bg-transparent ">
                  <img 
            src={profileval?.imageurl}
            crossOrigin="anonymous"
            alt={"AscentKnowMe_" + profileval?.firstName +" _ "+ profileval?.lastName}
            
            // class="imgclass brightness-125"
            class="brightness-125 object-contain  rounded-full w-[150px] h-[150px] object-center "

            

          />
                  </div>
                  <div><a href={process.env.REACT_APP_DOMAIN + qrloc.pathname} class="text-black ">
            
                  <span className="text-sm font-semibold">Check Profile</span>

          </a>{" "}</div>
                </div>
                {/* <div className="border-r border-x-2 bg-lime-500  h-[220px] flex flex-col ">
                  <p className="h-full w-[1.5px]"></p>
                </div> */}

              <div className=" bg-lime-500 h-full mt-3 flex flex-col "/>

                <div className="flex flex-col items-start m-2 w-full p-2">
                <p className="items-start justify-start text-base font-semibold w-full mb-2 ">{profileval?.firstName +" "+ profileval?.lastName}</p>                                
                <p className="items-start justify-start italic font-black  w-full mb-2 ">{profileval?.designation}</p>

                <div class="flex  flex-col w-full gap-1">
          <a href={"tel:" + profileval?.mobileno} class="font-semibold text-black no-underline flex justify-start items-center text-start gap-2">
            
            
          <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741179256/ICONS/phone_vjk726.jpg"}
            crossOrigin="anonymous"
           class="h-8 w-8 object-cover brightness-125"

                     />
            <span>{profileval?.mobileno}</span>
          </a>{" "}

          <a href={"mailto:" + profileval?.email} class=" text-black no-underline flex justify-start items-center text-start gap-2">
            
          <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741179256/ICONS/Email_x25ede.jpg"}
            crossOrigin="anonymous"
           class="h-8 w-8 object-cover brightness-125"

                     />


            <span>{profileval?.email}</span>
          </a>{" "}

          <a href={profileval?.website} class=" text-black no-underline flex justify-start items-center text-start gap-2">
            
          <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741179257/ICONS/website_tzd0cj.jpg"}
            crossOrigin="anonymous"
           class="h-8 w-8 object-cover brightness-125"

                     />
            <span>{profileval?.website}</span>
          </a>{" "}
        </div>

        <div className=" bg-lime-500 h-1 w-full mt-3 flex flex-col "/>
<div className="social  flex gap-3 mt-4 ">

{profileval?.youtube && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.youtube}
            target="_blank"
            class="link text-black no-underline  "
          >
           <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741177971/ICONS/vecteezy_set-of-social-media-icon-in-round-bakground__kwys2j.jpg"}
            crossOrigin="anonymous"
           class="h-10 w-10 object-cover brightness-125"

                     />
          </a>
          </div>
                    }

{/* Facebook */}

{profileval?.facebook && <div className=" flex flex-col gap-2 items-center "><a
            href={profileval?.facebook}
            target="_blank"
            // class="link text-[#1877F2] no-underline"
            class="link text-black no-underline"            
          >
            <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741177971/ICONS/facebook_fftyns.jpg"}
            crossOrigin="anonymous"
           class="h-10 w-10 object-cover brightness-125"

            

          />
           
          </a>
          </div>}



{/* //Instagram */}

          {profileval?.instagram && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.instagram}
            target="_blank"
            // class="link text-[#E4405F] no-underline"
            class="link text-black no-underline"            
          >
           <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741177971/ICONS/Instagram_l4cqhm.jpg"}
            crossOrigin="anonymous"
           class="h-10 w-10 object-cover brightness-125"

            

          />
           
          </a>
          </div>}



{/* //linkdin */}
    
    
{profileval?.linkedin && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.linkedin}
            target="_blank"
            // class="link text-[#0a66c2]"
            class="link text-black"            
          >
            <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741177971/ICONS/linkedin_f3awxu.jpg"}
            crossOrigin="anonymous"
           class="h-10 w-10 object-cover brightness-125"

            

          />
           
          </a>
          </div>}




{/* Social links */}
{/* X twitter */}
          {profileval?.socialx && <div className=" flex flex-col gap-2 items-center hidden"><a
            href={profileval?.socialx}
            target="_blank"
            class="link text-[#000000] no-underline"
          >
           <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741177970/ICONS/133590707_Social-Media-Logo-collection-CIRCLE-2023_lei7qg.jpg"}
            crossOrigin="anonymous"
           class="h-10 w-10 object-cover brightness-125"

            

          />

          </a>
          </div>}

{/* Gmap */}
{profileval?.gmap && <div className=" flex flex-col items-center"><a
            href={profileval?.gmap}
            target="_blank"
            class="link text-black no-underline"
          >
         <img 
            src={"https://res.cloudinary.com/sanketg/image/upload/v1741179256/ICONS/loc_tsh3wo.jpg"}
            crossOrigin="anonymous"
           class="h-10 w-10 object-cover brightness-125"

            

          />

          </a>
          </div>}



</div>

                </div>


                {/* <div className="flex items-center w-full justify-center ">
              <p className=" text-lime-500 text-sm underline underline-offset-2 " >
                {profileval?.companyName}</p>
            </div> */}



              </div>



              </div>

              


          </div>
        )}
        {expdate && <div className="flex justify-center items-center">
          <h1 className="text-2xl font-thin">Profile Expired ! Renew your Subscription</h1></div>}
      </div>
    );
  };

  return (
    <div className="mainPageAct mt-33 lg:mt-20 ">
      <NavBar></NavBar>
      {/* <Tabs defaultActiveKey="2">
        <Tabs.TabPane tab="Profile" key="1">
          <CompanyProfiles />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Preview" key="2">
          <ProfilePage />
        </Tabs.TabPane>
      </Tabs> */}

      <div className="justify-center flex bg-gray-50 w-screen ">{<Profile1 />}</div>
    </div>
  );
}

export default PublicProfile;
