import React, { useEffect, useState, useRef } from "react";
import NavBar from "../../../components/NavBar";
import { QRCode, Form, Input, Button, Image, Divider, message, Row, Col } from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/loadersSlice";
import { getProfileById } from "../../../apicalls/profiles";
import html2canvas from 'html2canvas';




// Sample product data (normally would come from Google Sheets)
// const sampleProducts = [
//   { id: '1', name: 'White Rice', brand: 'Harvest Gold', price: 50, uom: 'kg', stock: 100 },
//   { id: '2', name: 'Basmati Rice', brand: 'Royal', price: 120, uom: 'kg', stock: 75 },
//   { id: '3', name: 'Toor Dal', brand: 'Nature Fresh', price: 110, uom: 'kg', stock: 50 },
//   { id: '4', name: 'Sunflower Oil', brand: 'Fortune', price: 140, uom: 'liter', stock: 80 },
//   { id: '5', name: 'Sugar', brand: 'Harvest Gold', price: 40, uom: 'kg', stock: 120 },
//   { id: '6', name: 'Wheat Flour', brand: 'Aashirvaad', price: 45, uom: 'kg', stock: 90 }
// ];

const sampleProducts = [
  { id: '1', name: 'White Rice', brand: 'Harvest Gold' },
  { id: '2', name: 'Basmati Rice', brand: 'Royal' },
  { id: '3', name: 'Toor Dal', brand: 'Nature Fresh' },
  { id: '4', name: 'Sunflower Oil', brand: 'Fortune' },
  { id: '5', name: 'Sugar', brand: 'Harvest Gold' },
  { id: '6', name: 'Wheat Flour', brand: 'Aashirvaad' }
];



// PartyDetailsForm Component
const PartyDetailsForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({ name: '', contact: '', address: '' });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (errors[name]) setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Customer name is required';
    if (!formData.contact.trim()) {
      newErrors.contact = 'Contact number is required';
    } else if (!/^\d{10}$/.test(formData.contact.trim())) {
      newErrors.contact = 'Contact should be a 10-digit number';
    }
    if (!formData.address.trim()) newErrors.address = 'Address is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">Customer Name*</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className={`w-full p-2 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded`}
          placeholder="Enter customer name"
        />
        {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contact">Contact Number*</label>
        <input
          type="tel"
          id="contact"
          name="contact"
          value={formData.contact}
          onChange={handleChange}
          className={`w-full p-2 border ${errors.contact ? 'border-red-500' : 'border-gray-300'} rounded`}
          placeholder="Enter 10-digit contact number"
        />
        {errors.contact && <p className="text-red-500 text-xs mt-1">{errors.contact}</p>}
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">Delivery Address*</label>
        <textarea
          id="address"
          name="address"
          value={formData.address}
          onChange={handleChange}
          className={`w-full p-2 border ${errors.address ? 'border-red-500' : 'border-gray-300'} rounded`}
          rows="3"
          placeholder="Enter delivery address"
        ></textarea>
        {errors.address && <p className="text-red-500 text-xs mt-1">{errors.address}</p>}
      </div>

      <div className="flex justify-end">
        <button 
          type="submit" 
          className="bg-indigo-600 hover:bg-blue-700 text-white font-thin border-none py-2 px-6 rounded focus:outline-none focus:shadow-outline"
        >
          Continue to Products →
        </button>
      </div>
    </form>
  );
};

// ProductList Component
const ProductList = ({ products, addToOrder, selectedProducts }) => {
  const [quantityInputs, setQuantityInputs] = useState({});

  const handleQuantityChange = (productId, value) => {
    setQuantityInputs({
      ...quantityInputs,
      [productId]: Math.max(1, parseInt(value) || 1)
    });
  };

  const handleAddToOrder = (product) => {
    const quantity = quantityInputs[product.id] || 1;
    addToOrder(product, quantity);
    setQuantityInputs({ ...quantityInputs, [product.id]: 1 });
  };

  const isProductSelected = (productId) => {
    return selectedProducts.some(p => p.id === productId);
  };

  if (products.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-500">No products found. Try adjusting your search.</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
      {products.map(product => (
        <div 
          key={product.id} 
          className={`border rounded-lg overflow-hidden ${isProductSelected(product.id) ? 'border-blue-400 bg-blue-50' : 'border-gray-200'}`}
        >
          <div className="p-4 ">
            <h3 className="font-semibold text-lg">{product.name}</h3>
            <p className="text-gray-600 text-sm">{product.brand}</p>
            
            <div className="mt-4 flex items-center justify-between">
              <div className="flex items-center">
                <input
                  type="number"
                  min="1"
                  // value={quantityInputs[product.id] || 1}
                  value={quantityInputs[product.id] || 1}                  
                  onChange={(e) => handleQuantityChange(product.id, e.target.value)}
                  className="w-16 border border-gray-300 rounded p-1 text-center"
                />
              </div>
              
              <button
                onClick={() => handleAddToOrder(product)}
                className={`px-3 py-1 border-none rounded text-sm ${isProductSelected(product.id) ? 'bg-green-600 hover:bg-green-700 text-white' : 'bg-blue-600 hover:bg-blue-700 text-white border-none '}`}
              >
                {isProductSelected(product.id) ? 'Update' : 'Add'}
              </button>

            </div>
            <div className="w-full h-0.5 bg-gray-200 mt-2"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

// OrderSummary Component
const OrderSummary = ({ partyDetails, products, updateQuantity, removeFromOrder }) => {
  const totalAmount = products.reduce((sum, product) => sum + (product.quantity), 0);

  return (
    <div>
      <div className="bg-gray-50 p-4 rounded-lg mb-6">
        <h3 className="font-semibold text-lg mb-2">Customer Details</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <p className="text-sm text-gray-600">Name</p>
            <p className="font-medium">{partyDetails.name}</p>
          </div>
          <div>
            <p className="text-sm text-gray-600">Contact</p>
            <p className="font-medium">{partyDetails.contact}</p>
          </div>
          <div className="md:col-span-2">
            <p className="text-sm text-gray-600">Delivery Address</p>
            <p className="font-medium">{partyDetails.address}</p>
          </div>
        </div>
      </div>

      <div>
        <h3 className="font-semibold text-lg mb-2">Order Items</h3>
        
        {products.length === 0 ? (
          <p className="text-gray-500 text-center py-4">No products added to the order.</p>
        ) : (
          <div className="border rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
                  <th scope="col" className="py-3 px-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                  <th scope="col" className="py-3 px-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {products.map((product) => (
                  <tr key={product.id}>
                    <td className="py-4 px-4 whitespace-nowrap">
                      <div>
                        <div className="font-medium text-gray-900">{product.name}</div>
                        <div className="text-sm text-gray-500">{product.brand} </div>
                      </div>
                    </td>
                    <td className="py-4 px-4 whitespace-nowrap text-center">
                      <div className="flex items-center justify-center">
                        <button 
                          onClick={() => updateQuantity(product.id, Math.max(1, product.quantity - 1))}
                          className="p-1 rounded border border-gray-300 hover:bg-gray-100"
                        >-</button>
                        <span className="mx-2 w-8 text-center">{product.quantity}</span>
                        <button 
                          onClick={() => updateQuantity(product.id, product.quantity + 1)}
                          className="p-1 rounded border border-gray-300 hover:bg-gray-100"
                        >+</button>
                      </div>
                    </td>
                    <td className="py-4 px-4 whitespace-nowrap text-center">
                      <button 
                        onClick={() => removeFromOrder(product.id)}
                        className="text-white bg-red-600 border-none p-2"
                      >Remove</button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot className="bg-gray-50">
                <tr>
                  <td className="py-3 px-4 text-right font-semibold">Total Qty:</td>
                  <td className="py-3 px-4 text-center font-bold text-xl">{totalAmount.toFixed(0)}</td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};


// Main Application Component
const OrderingSystem = () => {
  const [products] = useState(sampleProducts);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [partyDetails, setPartyDetails] = useState({ name: '', contact: '', address: '' });
  const [step, setStep] = useState(1); // 1: Party Details, 2: Product Selection, 3: Order Summary
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBrand, setFilterBrand] = useState('');

  const filteredProducts = products.filter(product => {
    return (
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (filterBrand === '' || product.brand.toLowerCase() === filterBrand.toLowerCase())
    );
  });

  const brands = [...new Set(products.map(product => product.brand))];

  const addToOrder = (product, quantity) => {
    const existingProductIndex = selectedProducts.findIndex(p => p.id === product.id);
    
    if (existingProductIndex >= 0) {
      const updatedProducts = [...selectedProducts];
      updatedProducts[existingProductIndex].quantity += quantity;
      setSelectedProducts(updatedProducts);
    } else {
      setSelectedProducts([...selectedProducts, { ...product, quantity }]);
    }
  };

  const removeFromOrder = (productId) => {
    setSelectedProducts(selectedProducts.filter(p => p.id !== productId));
  };

  const updateQuantity = (productId, quantity) => {
    const updatedProducts = selectedProducts.map(product => 
      product.id === productId ? { ...product, quantity } : product
    );
    setSelectedProducts(updatedProducts);
  };

  const handlePartyDetailsSubmit = (details) => {
    setPartyDetails(details);
    setStep(2);
  };

  const goToSummary = () => {
    if (selectedProducts.length > 0) {
      setStep(3);
    }
  };

  const shareViaWhatsApp = () => {
    const orderText = generateOrderText(partyDetails, selectedProducts);
    const encodedText = encodeURIComponent(orderText);
    // window.open(`https://wa.me/91${partyDetails.contact}?text=${encodedText}`, '_blank');
    window.open(`https://wa.me/919075056050?text=${encodedText}`, '_blank');

  };

  const generateOrderText = (party, products) => {
    const date = new Date().toLocaleDateString();
    let text = `*New Order - ${date}*\n\n`;
    text += `*Customer Details:*\n`;
    text += `Name: ${party.name}\n`;
    text += `Contact: ${party.contact}\n`;
    text += `Address: ${party.address}\n\n`;
    
    text += `*Order Items:*\n`;
    products.forEach((product, index) => {
      text += `${index + 1}. ${product.name} (${product.brand}) - ${product.quantity} \n`;
    });
    
    const total = products.reduce((sum, product) => sum + (product.quantity), 0);
    text += `\n*Total Qty : ${total.toFixed(0)}*`;
    text += `-- \n\n`;    
    text += `powered by _Ascent Software Solutions_`;    
    return text;
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-indigo-600 text-white shadow-md">
        <div className="container mx-auto px-4 py-4">
          <h1 className="text-2xl font-thin text-center ">Self Ordering System</h1>
        </div>
        <footer className="bg-gray-100 border-t ">
        <div className="container mx-auto  text-center text-gray-600 p-1 text-sm">
          <p>Powered by Ascent Next</p>          
        </div>
      </footer>

      </header>

      <main className="container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          {step === 1 && (
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-semibold mb-4">Customer Details</h2>
              <PartyDetailsForm onSubmit={handlePartyDetailsSubmit} />
            </div>
          )}

          {step === 2 && (
            <div className="bg-white rounded-lg shadow-md p-2 ">
              <div className="flex items-center justify-between gap-2">
                <h2 className="text-xl font-semibold">Select Products</h2>
                <button 
                  onClick={() => setStep(1)} 
                  className="text-white bg-indigo-600 border-none flex justify-center items-center p-4"
                >
                  ← Back to Customer
                </button>
              </div>

              <div className="mt-6 flex justify-between items-center p-2">
                <div>
                  <span className="font-semibold flex flex-col  items-center">
                    {/* {selectedProducts.length} 
                    {selectedProducts.length === 1 ? ' item' : ' items'} selected */}
                  <span className="text-3xl p-2 items-center text-indigo-600 ">{selectedProducts.length} </span>
                  <span className="font-thin text-sm ">items Selected</span>
                  </span>
                </div>
                <button
                  onClick={goToSummary}
                  disabled={selectedProducts.length === 0}
                  className={`p-4 rounded text-xl items-center justify-center flex ${
                    selectedProducts.length === 0
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-indigo-600 text-white border-none hover:bg-blue-700'
                  }`}
                >
                  Review Order →
                </button>
              </div>


              <div className="mb-6">
                <div className="flex flex-col md:flex-row gap-4 mb-4">
                  <div className="flex-1">
                    <input
                      type="text"
                      placeholder="Search products..."
                      className="w-full p-2 border border-gray-300 rounded"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <div className="w-full md:w-48">
                    <select
                      className="w-full p-2 border border-gray-300 rounded"
                      value={filterBrand}
                      onChange={(e) => setFilterBrand(e.target.value)}
                    >
                      <option value="">All Brands</option>
                      {brands.map(brand => (
                        <option key={brand} value={brand}>{brand}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <ProductList 
                products={filteredProducts} 
                addToOrder={addToOrder} 
                selectedProducts={selectedProducts}
              />

              {/* <div className="mt-6 flex justify-between">
                <div>
                  <span className="font-semibold">
                    {selectedProducts.length} 
                    {selectedProducts.length === 1 ? ' item' : ' items'} selected
                  </span>
                </div>
                <button
                  onClick={goToSummary}
                  disabled={selectedProducts.length === 0}
                  className={`px-6 py-2 rounded ${
                    selectedProducts.length === 0
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-indigo-600 text-white border-none hover:bg-blue-700'
                  }`}
                >
                  Review Order →
                </button>
              </div> */}
            </div>
          )}

          {step === 3 && (
            <div className="bg-white rounded-lg shadow-md p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold">Order Summary</h2>
                <button 
                  onClick={() => setStep(2)} 
                  className="text-white bg-indigo-600 p-2 border-none"
                >
                  ← Back to Products
                </button>
              </div>

              <OrderSummary 
                partyDetails={partyDetails}
                products={selectedProducts}
                updateQuantity={updateQuantity}
                removeFromOrder={removeFromOrder}
              />

              <div className="mt-8 flex justify-end">
                <button
                  onClick={shareViaWhatsApp}
                  className="px-6 py-3 bg-green-600 text-white rounded-lg font-semibold flex items-center hover:bg-green-700"
                >
                  <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M17.498 14.382c-.301-.15-1.767-.867-2.04-.966-.273-.101-.473-.15-.673.15-.197.295-.771.964-.944 1.162-.175.195-.349.21-.646.075-.3-.15-1.263-.465-2.403-1.485-.888-.795-1.484-1.77-1.66-2.07-.174-.3-.019-.465.13-.615.136-.135.301-.345.451-.523.146-.181.194-.301.297-.496.1-.21.049-.375-.025-.524-.075-.15-.672-1.62-.922-2.206-.24-.584-.487-.51-.672-.51-.172-.015-.371-.015-.571-.015-.2 0-.523.074-.797.359-.273.3-1.045 1.02-1.045 2.475s1.07 2.865 1.219 3.075c.149.195 2.105 3.195 5.1 4.485.714.3 1.27.48 1.704.629.714.227 1.365.195 1.88.121.574-.091 1.767-.721 2.016-1.426.255-.705.255-1.29.18-1.425-.074-.135-.27-.21-.57-.345m-5.446 7.443h-.016c-1.77 0-3.524-.48-5.055-1.38l-.36-.214-3.75.975 1.005-3.645-.239-.375a9.869 9.869 0 01-1.516-5.26c0-5.445 4.455-9.885 9.942-9.885 2.654 0 5.145 1.035 7.021 2.91 1.875 1.859 2.909 4.35 2.909 6.99-.004 5.444-4.46 9.885-9.935 9.885M20.52 3.449C18.24 1.245 15.24 0 12.045 0 5.463 0 .104 5.334.101 11.893c0 2.096.549 4.14 1.595 5.945L0 24l6.335-1.652a12.062 12.062 0 005.71 1.447h.006c6.585 0 11.946-5.336 11.949-11.896 0-3.176-1.24-6.165-3.495-8.411"></path>
                  </svg>
                  Share via WhatsApp
                </button>
              </div>
            </div>
          )}
        </div>
      </main>
      
      <footer className="bg-gray-100 border-t mt-10 ">
        <div className="container mx-auto px-4 py-6 text-center text-gray-600">
          <p>© {new Date().getFullYear()} Self Ordering System</p>
          <p className="text-indigo-600 font-semibold text-sm">Powered by Ascent Next</p>          
        </div>
      </footer>
    </div>
  );
};

export default OrderingSystem;