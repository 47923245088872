import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ProtectedPage from "./components/ProtectedPage";
import Profile from "./pages/Profile";
import { useSelector } from "react-redux";
import { setLoading } from "./redux/loadersSlice";
import Spinner from "./components/Spinner";
import ProjectInfo from "./pages/ProjectInfo";
import Landing from "./pages/Landing";
import Pricing from "./pages/Pricing";
import PublicProfile from "./pages/Profile/PublicProfile";




import env from "react-dotenv";
import ForgotPassword from "./pages/ForgotPassword";
import QrProfile from "./pages/QrProfile";
import OrderingSystem from "./pages/Profile/PublicOrder";

function App() {

 // google review site
  // https://www.ascentindia.dev/grsite/gr_0001

  const { loading } = useSelector((state) => state.loaders);
  return (
    <div>
      {loading && <Spinner />}
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedPage>
                <Landing />
              </ProtectedPage>
            }
          />

          <Route
            path="/projectinfo/:id"
            element={
              <ProtectedPage>
                <ProjectInfo />
              </ProtectedPage>
            }
          />

          <Route path="/register" element={
              <ProtectedPage>
              <Register />
            </ProtectedPage>

          } />

          {/* <Route
            path="/profileview/:id"
            element={
              <ProtectedPage>
                <PublicProfile />
              </ProtectedPage>
            }
          /> */}

          <Route
            path="/profile"
            element={
              <ProtectedPage>
                <Profile />
              </ProtectedPage>
            }
          />
          <Route
            path="/qrprofile"
            element={
              <ProtectedPage>
                <QrProfile />
              </ProtectedPage>
            }
          />

          <Route path="/intro" element={<Landing />} />
          <Route path="/pricing" element={<Pricing />} />

          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />          
          <Route path="/profileview/:id" element={<PublicProfile />} />
          <Route path="/orderingsystem" element={<OrderingSystem />}/>
          {/* <Route path="/register" element={<Register />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
